<template>
  <div class="productBrand content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="getProductBrandList">
            <el-form-item label="品牌名称">
              <el-input v-model="name" @clear="getProductBrandList" placeholder="输入品牌名称搜索" clearable></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select @change="getProductBrandList" @clear="getProductBrandList" v-model="active" placeholder="请选择" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getProductBrandList" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addPojectBrandClick">添加品牌</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="品牌名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index==0"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index==0"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index ==tableData.length-1"></el-button>
            <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" v-prevent-click :disabled="scope.$index ==tableData.length-1"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--弹窗-->
    <el-dialog :title="isAdd ? '新增产品品牌' : '编辑产品品牌'" :visible.sync="dialogVisible" width="550px">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small" @submit.native.prevent>
          <el-form-item label="品牌名称" prop="Name">
            <el-input clearable v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-if="!isAdd">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="addSubmit" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>

  </div>
</template>


<script>
import API from "@/api/PSI/Product/productBrand";

export default {
  name: "productBrand",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      dialogVisible: false,
      modalLoading: false,
      tableData: [],
      name: "",
      active: true,
      isAdd: "",
      ruleForm: {
        Name: "",
        Active: true,
      },
      rules: {
        Name: [{ required: true, message: "请输入品牌名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "有效" : "无效";
    },
    /**  新增品牌弹窗  */
    addPojectBrandClick() {
      var that = this;
      that.dialogVisible = true;
      that.isAdd = true;
      that.ruleForm = {
        Name: "",
      };
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.dialogVisible = true;
      that.isAdd = false;
      that.ruleForm = Object.assign({}, row);
    },
    /**  保存 品牌信息    */
    addSubmit() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);
          if (that.isAdd) {
            that.createProductBrand(para);
          } else {
            that.updateProductBrand(para);
          }
        }
      });
    },

    // 移动首部
    upOneClick: function (row) {
      var that = this;
      that.moveProductBrand(row.ID, "");
    },
    // 移动尾部
    downOneClick: function (row, index) {
      var that = this;
      var tabIndex = that.tableData.length;
      var beforeId = "";
      if (index < tabIndex - 1) {
        beforeId = that.tableData[tabIndex - 1].ID;
      }
      that.moveProductBrand(row.ID, beforeId);
    },
    // 向上
    upClick: function (row, index) {
      var that = this;
      var beforeId = "";
      if (index > 1) {
        beforeId = that.tableData[index - 2].ID;
      }
      that.moveProductBrand(row.ID, beforeId);
    },
    // 向下
    downClick: function (row, index) {
      var that = this;
      var beforeId = "";
      if (index + 1 != that.tableData.length) {
        beforeId = that.tableData[index + 1].ID;
      }
      that.moveProductBrand(row.ID, beforeId);
    },

    // 获取产品品牌列表
    getProductBrandList: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      API.getProductBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增品牌
    createProductBrand: function (params) {
      let that = this;
      API.createProductBrand(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              showClose: true,
              message: "新增成功",
              duration: 2000,
            });
            that.getProductBrandList();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 编辑品牌
    updateProductBrand: function (params) {
      let that = this;
      API.updateProductBrand(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              showClose: true,
              message: "编辑成功",
              duration: 2000,
            });
            that.getProductBrandList();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },

    // 编辑品牌
    moveProductBrand: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      API.moveProductBrand(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.getProductBrandList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    let that = this;
    that.getProductBrandList();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style scoped lang="scss">
</style>
